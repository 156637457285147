export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_GROUPS = 'GET_GROUPS';
export const GROUP_ERROR = 'GROUP_ERROR';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const REMOVE_LOCAITON = 'REMOVE_LOCAITON';
export const EDIT_GROUP = 'EDIT_GROUP';
export const ADD_MAP = 'ADD_MAP';
